import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import "./ListPropertyForm.css"; // Import the CSS

const ListPropertyForm = () => {
  const [formData, setFormData] = useState({
    propertyTitle: "",
    propertyType: "",
    location: "",
    price: "",
    description: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., sending to an API)
    console.log(formData);
  };

  return (
    <>
      <Helmet>
        <title>Register Your Property</title>
      </Helmet>
      <Header />
      <div className="form-container">
        <h1 className="form-title">Register Your Property with Us</h1>
        <form onSubmit={handleSubmit} className="property-form">
          <div className="form-group">
            <label htmlFor="propertyTitle" className="form-label">Property Title</label>
            <input
              type="text"
              name="propertyTitle"
              id="propertyTitle"
              value={formData.propertyTitle}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="propertyType" className="form-label">Property Type</label>
            <select
              name="propertyType"
              id="propertyType"
              value={formData.propertyType}
              onChange={handleChange}
              className="form-select"
              required
            >
              <option value="">Select Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="House">House</option>
              <option value="Commercial">Commercial</option>
              <option value="Land">Land</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="location" className="form-label">Location</label>
            <input
              type="text"
              name="location"
              id="location"
              value={formData.location}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="price" className="form-label">Price</label>
            <input
              type="number"
              name="price"
              id="price"
              value={formData.price}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              className="form-textarea"
              required
            ></textarea>
          </div>

          <h2 className="contact-title">Contact Details</h2>

          <div className="form-group">
            <label htmlFor="contactName" className="form-label">Contact Name</label>
            <input
              type="text"
              name="contactName"
              id="contactName"
              value={formData.contactName}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="contactEmail" className="form-label">Contact Email</label>
            <input
              type="email"
              name="contactEmail"
              id="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="contactPhone" className="form-label">Contact Phone</label>
            <input
              type="tel"
              name="contactPhone"
              id="contactPhone"
              value={formData.contactPhone}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <button type="submit" className="form-submit-btn">Submit Property</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ListPropertyForm;

import React, { useEffect,useState } from "react";
import Header from "../components/header";
import Filter from "../components/properties/filter";
import Footer from "../components/footer";
import PropertyCard from "../components/property-card";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  getSalesPropertiesFn,
  getSalesCommunitiesFn,
  getAllSalesAgentsFn
} from "../context/endpoints";
import { useInView } from "react-intersection-observer";
import { useQueryParam, StringParam } from "use-query-params";
import RegisterForm from "../components/register";


export default function SalesProperties() {
  const { ref, inView } = useInView();
  const [collection, setCollection] = useQueryParam("collection", StringParam);
  const [category, setCategory] = useQueryParam("category", StringParam);
  
  const [location, setLocation] = useQueryParam("location", StringParam);
  const [AgentID, setAgentID] = useQueryParam("AgentID", StringParam);
  const [minBedrooms, setMinBedrooms] = useQueryParam("minBedrooms", StringParam);
  const [maxBedrooms, setMaxBedrooms] = useQueryParam("maxBedrooms", StringParam);
  const [minPrice, setMinPrice] = useQueryParam("minPrice", StringParam);
  const [maxPrice, setMaxPrice] = useQueryParam("maxPrice", StringParam);
  const [AgentName, setAgentName] = useQueryParam("AgentName", StringParam);

  const [showPopup, setShowPopup] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem('hasShownPopup');
    const hasFormSubmitted = sessionStorage.getItem('hasFormSubmitted');

    // Check if popup has not been shown and form has not been submitted
    if (!hasShownPopup && !hasFormSubmitted) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('hasShownPopup', 'true');
      }, 3000); // Delay of 2 seconds

      return () => clearTimeout(timer);
    }
  }, []);

  const handleEnquireClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitStatus = (success, result) => {
    if (success) {
      // setHasSubmitted(true);
      sessionStorage.setItem('hasFormSubmitted', 'true');
      // setShowPopup(false); // Hide the popup after submission
    } else {
      console.log('Form submission failed.');
    }
  };
  
  // This code defines a function that fetches data from the getRentalsPropertiesFn endpoint and returns the data. It also defines a useInfiniteQuery hook that fetches data by collection, location, bedrooms, minPrice, and maxPrice and next page.
  const { data, error, fetchNextPage, hasNextPage, isFetching, status } =
    useInfiniteQuery({
      queryKey: [
        "SalesPropertiesData",
        collection,
        location,
        AgentID,
        minBedrooms,
        maxBedrooms,
        minPrice,
        maxPrice,
        category
      ],
      queryFn: ({ pageParam = 1 }) =>
        getSalesPropertiesFn({
          collection,
          location,
          minBedrooms,
          maxBedrooms,
          AgentID,
          minPrice,
          maxPrice,
          pageParam,
          category
        }),
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.pages) {
          return lastPage?.page + 1;
        }
        return undefined;
      },
    });

  // This code defines a useQuery hook that fetches data from the getRentalsCommunitiesFn endpoint and returns the the communities data.
  const communitiesData = useQuery({
    queryKey: ["SalesCommunitiesData"],
    queryFn: () => getSalesCommunitiesFn(),
  });

  // This code sets number of properties found
  let propertiesfound = "0";
  if (status !== "pending" && status !== "error") {
    propertiesfound = data.pages[0].propertiesfound;
  }

  // This code sets communities if communities data is not pending and there is no error
  let communities = {};
  if (!communitiesData.isPending && !communitiesData.error) {
    communities = communitiesData.data.communities;
  }


    // This code defines a useQuery hook that fetches data from the getRentalsCommunitiesFn endpoint and returns the the communities data.
  const AgentsData = useQuery({
      queryKey: ["GetAllSalesAgent"],
      queryFn: () => getAllSalesAgentsFn(),
    });
  let Agents = [];

  if (!AgentsData.isPending && !AgentsData.error) {
    Agents = AgentsData.data.agents;
  }

 
  // This code to manage infinite scrolling
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const loadingSkeleton = Array.from({ length: 3 }).map((_, index) => (
    <Box key={index} style={{ margin: "60px 0" }}>
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: "259px" }} />
      </Skeleton>
      <Skeleton variant="text" width="100%">
        <div style={{ paddingTop: "65px", marginBottom: "-9px" }} />
      </Skeleton>
      <Skeleton variant="text" width="100%">
        <div style={{ paddingTop: "23px" }} />
      </Skeleton>
    </Box>
  ));

  
  return (
    <>
      <Helmet>
        <title>Properties for sales - BlackBrick</title>
      </Helmet>
      <Header />
      <section className="properties-section">
        <div className="properties-header">
          <div className="container">
            <h1>properties for sales</h1>
            <h2>
              Buy the most luxurious homes in the most desirable Lifestyle
              Destinations in Dubai.
            </h2>
            <div className="btn-enquire-listing home-listings-button">
              <button onClick={handleEnquireClick}>Enquire Now</button>
            </div>
            {showPopup && !hasSubmitted && (
              <RegisterForm onClose={handleClosePopup} onSubmitStatus={handleSubmitStatus} />
            )}
            <p>
            Explore the finest homes in Dubai’s most sought-after lifestyle destinations. Our exclusive portfolio offers a selection of stunning properties, carefully curated to meet your aspirations. If you don’t find what you’re looking for, simply reach out—our team will go above and beyond to find your perfect home.
            </p>
          </div>
          <Filter
            collection={collection}
            setCollection={setCollection}
            AgentID ={AgentID}
            setAgentID={setAgentID}
            location={location}
            setLocation={setLocation}
            minBedrooms={minBedrooms}
            setMinBedrooms={setMinBedrooms}
            maxBedrooms={maxBedrooms}
            setMaxBedrooms={setMaxBedrooms}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            AgentName={AgentName}
            setAgentName={setAgentName}
            propertiesfound={propertiesfound}
            communities={communities}
            Agents = {Agents}
            Category = {category}
            setCategory = {setCategory}
          />
        </div>
        <div className="container">
          <div className="properties-listings">
            {status === "pending" ? (
              <>{loadingSkeleton}</>
            ) : status === "error" ? (
              <ErrorMessage>{error.message}</ErrorMessage>
            ) : (
              <>
                {data.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.properties.map((property, index) => (
                      <PropertyCard
                        key={index}
                        cardLink={`/properties/buy/property/${property.RefNo}`}
                        cardImage={property.Images?.Image}
                        cardTitle={property.MarketingTitle}
                        cardLocation={property.Community}
                        cardBedrooms={property.Bedrooms}
                        cardPrice={property.SellPrice}
                        priceOnApplication ={property.PriceOnApplication}
                        RefNo={property.RefNo}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
            {isFetching && <>{loadingSkeleton}</>}
            <p ref={ref}></p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

import React from "react";
import styles from "./PhoneInput.module.css";

const PhoneInputField = ({ isPropertyField, onChange, value }) => {
  return (
    <div
      className={
        isPropertyField ? styles.propertyFieldContainer : styles.inputContainer
      }
    >
      <input
        type="tel"
        placeholder="Enter Phone (e.g +14155552675)"
        value={value}
        onChange={(e) => onChange(e.target.value)} // Pass the input value to onChange handler
        className={styles.phoneInput} // Apply styles to the input box
        style={{
          width: "100%",
          border: "none",
          outline: "none",
          boxShadow: "none",
          fontSize: "13px",
          backgroundColor: isPropertyField ? "transparent" : "#fff",
          // borderBottom: "1px solid #ccc", // Example styling for bottom border
          padding: "10px",
          // color: "black",
        }}
      />
    </div>
  );
};

export default PhoneInputField;
